import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select, Input, Switch, InputNumber, TimePicker, Drawer, Tabs } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { db } from 'firebase/firebase';
import { collection } from 'firebase/firestore';
import { useFirestoreQuery } from 'packages/utils';
import { CaretDownOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { actions, LOC_TYPES } from 'packages/locations';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import FormSelect from 'components/Form/components/FormSelect';
import LocationCombiner, { getLocationCombinerStatus } from 'components/Location/LocationCombiner';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { FORMS } from '../../constants';
import ModalLabel from '../ModalLabel';
import GpsForm from '../GpsForm';
import { useResetFormOnCloseModal } from '../../util';
import styles from './styles.module.less';
import { eventTypeSummaryPropTypes } from '../../../utils/proptypes/eventTypes';

const { Item } = Form;
const { Option } = Select;
const FORMAT = 'HH:mm';
const INIT_LOCATIONS = {
  isAGroup: -1,
  data: [],
};

const TriggersForm = ({
  isVisible = false,
  onCancel,
  initialData = null,
  divId = null,
  handleInitData,
  triggerIdsSelected = [],
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [gpsEdit, setGpsEdit] = useState(null);
  const [hasCustomName, setHasCustomName] = useState(false);
  const [triggerSelected, setTriggerSelected] = useState(false);
  const [newOrExistingTrigger, setNewOrExistingTrigger] = useState('0');
  const [locations, setLocations] = useState(INIT_LOCATIONS);

  const orgId = useSelector(({ organizations }) => organizations.organization.id);
  const divEventTypesRef = isVisible
    ? collection(db, 'organizations', orgId, 'divisions', divId, 'event_types')
    : null;
  const { data: divETs } = useFirestoreQuery(divEventTypesRef, [orgId, divId, isVisible]);

  const divLocationsRef = isVisible
    ? collection(db, 'organizations', orgId, 'divisions', divId, 'locations')
    : null;
  const divTriggersRef = isVisible
    ? collection(db, 'organizations', orgId, 'divisions', divId, 'triggers')
    : null;

  const { data: triggersData = [], triggersLoading } = useFirestoreQuery(divTriggersRef, [
    orgId,
    divId,
    isVisible,
  ]);
  const { data: divLocations = [], divLocationsLoading } = useFirestoreQuery(divLocationsRef, [
    orgId,
    divId,
    isVisible,
  ]);

  const triggerOptions = [...triggersData, ...[{ id: '', name: '' }]];
  const isTriggerSelected = tr => triggerIdsSelected.includes(tr);
  const isEditing = initialData && Object.values(initialData).length > 0;
  const hiddenPerNewTrigger =
    form.getFieldValue('triggerId') === '' && newOrExistingTrigger === '0' && !isEditing;

  useResetFormOnCloseModal({
    form,
    isVisible,
  });

  const mainLoading = triggersLoading || divLocationsLoading;

  const onOk = () => {
    // NOTE: before we was saving eventTypeId , but now we are goign to take eventTypeSummary Schema
    const triggerToBeSaved = form.getFieldValue();
    const eventTypeLikeString = typeof triggerToBeSaved?.eventType === 'string' ?? false;
    const eventTypedFounded = eventTypeLikeString
      ? divETs.find(item => item.id === triggerToBeSaved.eventType)
      : triggerToBeSaved.eventType;
    const triggerParsed = {
      ...triggerToBeSaved,
      eventType: eventTypedFounded,
    };

    if (locations?.isAGroup && Object.values(locations?.data || {}).length > 0) {
      triggerParsed.locationGroup = locations.data || {};
      triggerParsed.locations = [];
    } else {
      triggerParsed.locations = locations?.data || [];
      delete triggerParsed.locationGroup;
    }

    form.setFieldsValue(triggerParsed);
    form.submit();
  };

  const savingLocations = trigger => {
    if (!trigger) return;

    const isAGroup = getLocationCombinerStatus(trigger);
    const newData = isAGroup ? trigger?.locationGroup : trigger?.locations || [];

    setLocations({
      isAGroup,
      data: newData,
    });
  };

  const editGpsLoc = useCallback(
    data => {
      const { id, ...rest } = gpsEdit;
      const isDivLocation = !!divLocations.find(loc => loc.id === id);
      dispatch(
        actions.locationSaveFetch(orgId, isDivLocation ? divId : null, id, {
          ...rest,
          distance: data,
        }),
      );
      setGpsEdit(null);
    },
    [dispatch, divId, divLocations, gpsEdit, orgId],
  );

  const handleTriggerSelector = triggerId => {
    if (!triggerId) {
      form.resetFields();
      setTriggerSelected(false);
      return;
    }

    const trigger = triggersData.find(item => item?.id === triggerId);
    const { name, eventType, description = '', avoidConsecutiveTriggering, cooldown } = trigger;

    const triggerParsed = {
      eventType: eventType?.id || eventType || null,
      name,
      description,
      avoidConsecutiveTriggering,
      cooldown: moment()
        .startOf('day')
        .add(cooldown || 0, 'minutes'),
    };
    form.setFieldsValue(triggerParsed);
    setTriggerSelected(true);

    savingLocations(trigger);
  };

  const createOrExistingOptions = [
    {
      key: '0',
      id: '0',
      label: <IntlMessages id="schedule.form.triggers.existingTrigger.option" />,
    },
    { key: '1', id: '1', label: <IntlMessages id="schedule.form.triggers.newTrigger.option" /> },
  ];

  useEffect(() => {
    if (isVisible) {
      setHasCustomName(false);
      setTriggerSelected(false);

      savingLocations(initialData);

      form.resetFields();
    } else {
      setNewOrExistingTrigger('0');
    }
  }, [form, initialData, initialData?.locations, initialData?.locationGroup, isVisible]);

  useEffect(() => {
    if (initialData && Object.values(initialData).length > 0) {
      form.resetFields();
    }

    if (initialData?.triggerId) setTriggerSelected(true);
    else {
      setTriggerSelected(false);
      setNewOrExistingTrigger('0');
    }
  }, [form, initialData, handleInitData]);

  return (
    <Drawer className={styles.mainContainer} open={isVisible} closable={false} footer={null}>
      <BoxContainer>
        <BoxContainer content fixed>
          <FilterContainer
            goBack={onCancel}
            title={
              <Title.Header
                className="gx-pt-1 gx-pb-1"
                value={<IntlMessages id="schedule.form.triggers.modal.title" />}
              />
            }
            actionButtons={[
              {
                label: <IntlMessages id="button.save" />,
                type: 'primary',
                action: onOk,
              },
            ]}
          />
        </BoxContainer>
        <BoxContainer content loading={mainLoading}>
          <Form
            form={form}
            initialValues={{
              triggerId: initialData?.triggerId || '',
              cooldown: moment()
                .startOf('day')
                .add(+initialData?.cooldown || 0, 'minutes'),
              description: initialData?.description || '',
              name: initialData?.name || '',
              expectedExecutions: +initialData?.expectedExecutions || 1,
              avoidConsecutiveTriggering: !!initialData?.avoidConsecutiveTriggering,
              eventType: initialData?.eventType.id || initialData?.eventType || null,
            }}
            layout="vertical"
            name={FORMS.TRIGGERS}
            className={styles.customTriggerForm}
          >
            <Item hidden={isEditing} name="newOrExistingTrigger">
              <Tabs
                items={createOrExistingOptions}
                onChange={val => {
                  setNewOrExistingTrigger(val);
                  setTriggerSelected(false);
                  form.resetFields([
                    'triggerId',
                    'cooldown',
                    'description',
                    'eventType',
                    'name',
                    'expectedExecutions',
                    'locations',
                    'avoidConsecutiveTriggering',
                  ]);
                  setLocations(INIT_LOCATIONS);
                }}
              />
            </Item>
            <Item
              label={
                <Title.LabelForm
                  value={<IntlMessages id="schedule.form.triggers.trigger.label" />}
                />
              }
              name="triggerId"
              className={styles.tooltipLabel}
              hidden={newOrExistingTrigger !== '0' || isEditing}
            >
              <Select
                onClear={() => {
                  form.resetFields();
                  handleInitData(null);
                }}
                allowClear
                onChange={e => handleTriggerSelector(e)}
                className={styles.selectInput}
                suffixIcon={<CaretDownOutlined className={styles.selectIcon} />}
              >
                {triggerOptions?.map(item => (
                  <Option
                    disabled={isTriggerSelected(item.id)}
                    key={item.id}
                    value={item.id}
                    name={item.name}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              hidden={hiddenPerNewTrigger}
              label={
                <ModalLabel
                  label="schedule.form.triggers.modal.forms.label"
                  description="schedule.form.triggers.modal.eventType.description"
                />
              }
              name="eventType"
              className={styles.tooltipLabel}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'schedule.form.triggers.modal.eventType.requiredMessage',
                  }),
                },
              ]}
            >
              <FormSelect
                showSearch
                disabled={triggerSelected}
                onChange={(_, op) => {
                  if (!hasCustomName) {
                    form.setFieldsValue({
                      name: op.name,
                    });
                  }
                }}
                className={styles.selectInput}
                options={divETs}
              />
            </Item>
            <Item
              hidden={hiddenPerNewTrigger}
              label={
                <ModalLabel
                  label="schedule.form.triggers.modal.name.label"
                  description="schedule.form.triggers.modal.name.description"
                />
              }
              name="name"
              className={styles.tooltipLabel}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'schedule.form.triggers.modal.name.requiredMessage',
                  }),
                },
              ]}
            >
              <Input
                disabled={triggerSelected}
                onChange={() => setHasCustomName(true)}
                placeholder={intl.formatMessage({
                  id: 'schedule.form.triggers.modal.name.placeholder',
                })}
              />
            </Item>
            <Item
              hidden={hiddenPerNewTrigger}
              label={
                <ModalLabel
                  label="schedule.form.triggers.modal.description.label"
                  description="schedule.form.triggers.modal.description.description"
                />
              }
              name="description"
              className={styles.tooltipLabel}
            >
              <Input disabled={triggerSelected} />
            </Item>
            <Item className="gx-mt-3" hidden={hiddenPerNewTrigger} noStyle>
              <KeyValueWidget
                label={
                  <>
                    <Title.LabelForm value={<IntlMessages id="general.locations" />} />
                    <Title.Description value={<IntlMessages id="locations.description" />} />
                  </>
                }
                value={
                  <LocationCombiner
                    disabled={triggerSelected}
                    value={locations}
                    divisionId={divId}
                    onChange={setLocations}
                  />
                }
              />
            </Item>
            {!hiddenPerNewTrigger && (
              <h4 className="gx-guarnic-label gx-pt-2 gx-pb-2">
                <IntlMessages id="schedule.form.triggers.modal.sectionTitle.accomplishment" />
              </h4>
            )}
            <Item
              hidden={hiddenPerNewTrigger}
              label={
                <ModalLabel
                  label="schedule.form.triggers.modal.expectedExecutions.label"
                  description="schedule.form.triggers.modal.expectedExecutions.description"
                />
              }
              name="expectedExecutions"
              className={styles.tooltipLabel}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'schedule.form.triggers.modal.expectedExecutions.requiredMessage',
                  }),
                },
              ]}
            >
              <InputNumber min={1} className={styles.fullWidth} />
            </Item>
            <Item
              hidden={hiddenPerNewTrigger}
              label={
                <ModalLabel
                  label="schedule.form.triggers.modal.avoidConsecutiveTriggering.label"
                  description="schedule.form.triggers.modal.avoidConsecutiveTriggering.description"
                />
              }
              name="avoidConsecutiveTriggering"
              valuePropName="checked"
              className={styles.tooltipLabel}
            >
              <Switch disabled={triggerSelected} />
            </Item>
            <Item
              hidden={hiddenPerNewTrigger}
              label={
                <ModalLabel
                  label="schedule.form.triggers.modal.cooldown.label"
                  description="schedule.form.triggers.modal.cooldown.description"
                />
              }
              className={styles.tooltipLabel}
            >
              <Item
                name="cooldown"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'schedule.form.triggers.modal.cooldown.requiredMessage',
                    }),
                  },
                ]}
              >
                <TimePicker
                  placement="topLeft"
                  disabled={triggerSelected}
                  className={classnames('gx-m-0', styles.cooldownInput)}
                  format={FORMAT}
                  minuteStep={5}
                  showNow={false}
                  placeholder={intl.formatMessage({
                    id: 'schedule.form.triggers.modal.cooldown.placeholder',
                  })}
                />
              </Item>
            </Item>
          </Form>
          <GpsForm
            isVisible={!!gpsEdit}
            onCancel={() => setGpsEdit(null)}
            onSubmit={editGpsLoc}
            initialData={{ ...gpsEdit?.distance }}
          />
        </BoxContainer>
      </BoxContainer>
    </Drawer>
  );
};

TriggersForm.propTypes = {
  divId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  initialData: PropTypes.shape({
    triggerId: PropTypes.string,
    cooldown: PropTypes.number,
    description: PropTypes.string,
    eventType: eventTypeSummaryPropTypes.isRequired,
    name: PropTypes.string.isRequired,
    expectedExecutions: PropTypes.number.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(LOC_TYPES)).isRequired,
        name: PropTypes.string.isRequired,
        distance: PropTypes.shape({
          meters: PropTypes.number.isRequired,
          outside: PropTypes.bool.isRequired,
        }),
      }),
    ),
    avoidConsecutiveTriggering: PropTypes.bool,
  }),
  handleInitData: PropTypes.func.isRequired,
  triggerIdsSelected: PropTypes.array,
};

export default TriggersForm;
